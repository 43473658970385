const today = new Date();
const disclaimerDate = `${new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
}).format(today)}`;
export const DentalText = {
    dentalTitle: {
        english: 'Dental insurance',
        spanish: 'Seguro dental',
    },
    claimsTitle: {
        english: 'Most recent claims',
        spanish: 'Reclamaciones más recientes',
    },
    coveredServicesTitle: {
        english: 'Covered services',
        spanish: 'Servicios cubiertos',
    },
    deductiblesTitle: {
        english: 'Deductibles and coinsurance',
        spanish: 'Deducibles y coseguro',
    },
    dropdownText: {
        english: 'Select a member',
        spanish: 'Seleccione un miembro',
    },
    effectiveDateText: {
        english: 'Effective date',
        spanish: 'Fecha de entrada en vigor',
    },
    terminationDateText: {
        english: 'Term date',
        spanish: 'Fecha del plazo',
    },
    dateOfBirthText: {
        english: 'Date of birth',
        spanish: 'Fecha de nacimiento',
    },
    coveredServicesText: {
        english: 'Covered services',
        spanish: 'Servicios cubiertos',
    },
    deductibleText: {
        english: 'Deductibles and coinsurance',
        spanish: 'Deducibles y coaseguro',
    },
    claimsText: {
        english: 'Claims',
        spanish: 'Reclamaciones',
    },
    backText: {
        english: 'Back',
        spanish: 'Atrás',
    },
    disclaimerText: {
        english: `The summary provided below is based on processed claims as of ${disclaimerDate}. Future benefits will be applied in the order that claims are received and processed.`,
        spanish: `El resumen que se proporciona a continuación se basa en las reclamaciones procesadas a partir del ${disclaimerDate}. Los beneficios futuros se aplicarán en el orden en que se reciban y procesen las reclamaciones.`,
    },
    deductibleAmountPaidText: {
        english: 'Amount paid towards deductible',
        spanish: 'Monto pagado para el deducible',
    },
    deductiblesText: {
        english: 'deductibles',
        spanish: 'deducibles',
    },
    coinsuranceText: {
        english: 'coinsurance',
        spanish: 'coaseguro',
    },
    coinsuranceTitleText: {
        english: 'Coinsurance',
        spanish: 'Coaseguro',
    },
    youPayText: {
        english: 'You pay',
        spanish: 'Tú pagas',
    },
    deductibleAppliesText: {
        english: 'Deductible applies',
        spanish: 'Se aplica deducible',
    },
    planMaximumsText: {
        english: 'Plan maximums',
        spanish: 'Máximos del Plan',
    },
    individualAnnualText: {
        english: 'Individual annual used to date',
        spanish: 'Anual individual utilizada hasta la fecha',
    },
    indvidualLifetimeText: {
        english: 'Individual lifetime ortho used to date',
        spanish: 'Ortopedia individual de por vida utilizada hasta la fecha',
    },
    maxRewardsText: {
        english: 'Max rewards available',
        spanish: 'Recompensas máximas disponibles',
    },
    planMaximumsAnnualText: {
        english: 'For Preventative, Basic, & Major Services Combined',
        spanish: 'Para Servicios Preventivos, Básicos y Mayores Combinados',
    },
    deductibleAndCoinsuranceNotFoundWarning: {
        english: `We're sorry for the inconvenience, but we are unable to provide deductible and coinsurance information for this plan. Please contact us for further assistance.`,
        spanish: `Lamentamos el inconveniente, pero no podemos proporcionar información sobre el deducible y el coseguro para esto plan. Por favor contáctenos para obtener más ayuda.`,
    },
    generalError: {
        english: 'Error: We are unable to retrieve this information at this time. Please try again later.',
        spanish: 'Error: No podemos recuperar esta información en este momento. Inténtelo de nuevo más tarde.',
    },
    planOverviewConditionsTitle: {
        english: 'Additional eligibility conditions',
        spanish: 'Condiciones de elegibilidad adicionales',
    },
    IdCardTitle: {
        english: 'ID card',
        spanish: 'Tarjeta de identificación',
    },
    IdCardText: {
        english: 'Download, print or save your ID cards here!',
        spanish: '¡Descarga, imprime o guarda tus tarjetas de identificación aquí!',
    },
    findADentistTitle: {
        english: 'Find a dentist',
        spanish: 'Encuentre una dentista',
    },
    findADentistText: {
        english: 'Find a local dentist for all your needs!',
        spanish: '¡Encuentre un dentista local para todas sus necesidades!',
    },
    downloadBenefitsTitle: {
        english: 'Download benefits',
        spanish: 'Descargar beneficios',
    },
    downloadBenefitsTitleSmallest: {
        english: 'Benefits',
        spanish: 'Beneficios',
    },
    findADentistTitleSmallest: {
        english: 'Dentist',
        spanish: 'Dentista',
    },
    IdCardTitleSmallest: {
        english: 'Id card',
        spanish: 'Tarjeta de identificación',
    },
    downloadBenefitsText: {
        english: 'Download, print, or save your Benefits here!',
        spanish: '¡Descarga, imprime o guarda tus Beneficios aquí!',
    },
    pdfError: {
        english: 'There was a problem downloading the PDF. Please try again.',
        spanish: 'Hubo un problema al descargar el PDF. Por favor inténtalo de nuevo.',
    },
    preventiveServices: {
        english: 'Preventive Services',
        spanish: 'Servicios Preventivos',
    },
    basicServices: {
        english: 'Basic Services',
        spanish: 'Servicios Básicos',
    },
    majorServices: {
        english: 'Major Services',
        spanish: 'Servicios Mayores',
    },
    orthodontia: {
        english: 'Orthodontia',
        spanish: 'Ortodoncia',
    },
    details: {
        english: 'Details',
        spanish: 'Detalles',
    },
    frequency: {
        english: 'Frequency',
        spanish: 'Frecuencia',
    },
    ageLimit: {
        english: 'Age limit',
        spanish: 'Límite de edad',
    },
    additionalInfo: {
        english: 'Additional Information',
        spanish: 'Información adicional',
    },
    additionalResources: {
        english: 'Additional resources',
        spanish: 'Recursos adicionales',
    },
    additionalResourcesForms: {
        english: 'Forms',
        spanish: 'Formas',
    },
    disclaimerTextPlanFundingAddition: {
        english: ' If there are differences between this information and the policy, the terms of the policy control.',
        spanish: ' Si hay diferencias entre esta información y la póliza, los términos de la póliza controlan.',
    },
    hipaaDisclaimer: {
        english: 'Due to HIPAA privacy regulations, information about a spouse or adult dependent dental claim cannot be provided to a member without appropriate authorization. To obtain this information, a spouse or adult dependent can contact us directly.,',
        spanish: 'Debido a las regulaciones de privacidad de HIPAA, la información sobre un reclamo dental de un cónyuge o dependiente adulto no se puede proporcionar a un miembro sin la autorización adecuada. Para obtener esta información, un cónyuge o dependiente adulto puede contactarnos directamente.',
    },
    obtainDisclaimerText: {
        english: 'For a member to obtain this information: ',
        spanish: 'Para que un miembro obtenga esta información: ',
    },
    authorizationFormDisclaimer: {
        english: 'If Lincoln has a valid authorization form on file (dated within the last 24 months), you may contact us and obtain information about the dependent. Please note: the adult dependent information will not be viewable on the Group Protection Online Services website.',
        spanish: 'Si Lincoln tiene un formulario de autorización válido en el archivo (fechado dentro de los últimos 24 meses), puede contactarnos y obtener información sobre el dependiente. Tenga en cuenta: la información del dependiente adulto no será visible en el sitio web de Servicios en línea de protección',
    },
    dentalInfoReleaseDisclaimerA: {
        english: 'If Lincoln does not have a valid authorization form on file, the dependent must complete the ',
        spanish: 'Si Lincoln no tiene un formulario de autorización válido en el archivo, el dependiente debe completar el ',
    },
    dentalInfoReleaseFormLinkText: {
        english: 'Authorization for Release of Dental Information',
        spanish: 'Autorización para la divulgación de información dental',
    },
    dentalInfoReleaseDisclaimerB: {
        english: ' and forward to Lincoln via e-mail, fax, or direct mail.',
        spanish: ' y enviar a Lincoln por correo electrónico, fax o correo directo.',
    },
    continuedParticipationDisclaimerA: {
        english: "If using a PPO dentist, it is the employee's responsibility to confirm the dentist's continuing participation in the network associated with the covered employee’s plan at the time of treatment. Not sure of your network? Refer to your ",
        spanish: 'Si utiliza un dentista de PPO, es responsabilidad del empleado confirmar la participación continua del dentista en la red asociada con el plan del empleado cubierto en el momento del tratamiento. ¿No está seguro de su red? Consulte su ',
    },
    dentalIdCardLink: {
        english: 'Dental ID card',
        spanish: 'Tarjeta de identificación dental',
    },
    continuedParticipationDisclaimerB: {
        english: ', or contact Customer Service at 800-423-2765.',
        spanish: ', o comuníquese con el Servicio al Cliente al 800-423-2765.',
    },
    waitingPeriodNotFoundError: {
        english: `We're sorry for the inconvenience, but we are unable to provide waiting period information for this plan. Please contact us for further assistance.`,
        spanish: `Lamentamos el inconveniente, pero no podemos proporcionar información sobre el período de espera para este plan. Por favor contáctenos para obtener más ayuda.`
    },
    waitingPeriodGeneralError: {
        english: `Error: We are unable to provide waiting period information at this time. Please try again later.`,
        spanish: `Error: no podemos proporcionar información sobre el período de espera en este momento. Inténtelo de nuevo más tarde.`
    },
    benefitsAndFrequencyGeneralError: {
        english: `Error: We are unable to provide benefits and frequencies information at this time. Please try again later.`,
        spanish: `No podemos proporcionar información sobre beneficios y frecuencias en este momento. Inténtelo de nuevo más tarde.`
    },
    benefitsAndFrequencyNotFoundError: {
        english: `We're sorry for the inconvenience, but we are unable to provide benefits and frequencies information for this plan. Please contact us for further assistance.`,
        spanish: `Lamentamos el inconveniente, pero no podemos brindar información sobre los beneficios y frecuencias de este plan. Por favor contáctenos para obtener más ayuda.`
    },
    claimsForText: {
        english: 'For ',
        spanish: 'Para ',
    },
    downloadClaimText: {
        english: 'Download claim overview',
        spanish: 'Descargar resumen de reclamación',
    },
    hippaViolationText: {
        english: 'Due to HIPAA privacy regulations, information about a spouse or adult dependent dental claim cannot be provided to a member without appropriate authorization. To obtain this information, a spouse or adult dependent can contact us directly.',
        spanish: 'Debido a las regulaciones de privacidad de HIPAA, la información sobre un reclamo dental de un cónyuge o dependiente adulto no se puede proporcionar a un miembro sin la autorización adecuada. Para obtener esta información, un cónyuge o dependiente adulto puede contactarnos directamente.',
    },
};
export default DentalText;
