import React from 'react';
import { Row, Col, TextInput, SelectInput, Heading, DatePickerV2, themes } from '@digitools/honest-react';
import useExperience from '../../../hooks/useExperience';
import useTranslate from '../../../hooks/useTranslate';
import { Language } from '../../../types/Language';
import { PersonalInformationText } from '../constants/PersonalInformationText';
import useCliDataContext from '../hooks/useCliDataContext';
//TODO: move these utils into highest level utils folder
import { fieldRequired, selectInputErrorMessage } from '../../eoi/utils/FormFieldValidations';
import { dateMask, middleInitialMask } from '../../eoi/utils/InputMasks';
import { Scope } from 'informed';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
import useAuthentication from 'src/hooks/useAuthentication';
import { Experience } from 'src/types/Experience';
import { validateDateIsOnOrBeforeToday, validateName } from '../utils/validationUtils';
/**
 * Section of fields for personalInformation, for use within a <Form>
 */
const PersonalInformation = () => {
    const { user } = useAuthentication();
    const { t } = useTranslate();
    const { language, experience } = useExperience();
    const { claimantData } = useCliDataContext();
    const genderValues = () => {
        return [
            {
                label: t(PersonalInformationText.male),
                value: 'M',
            },
            {
                label: t(PersonalInformationText.female),
                value: 'F',
            },
        ];
    };
    return (<div data-public={true} data-testid='personal-information' style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <BorderLessGrayBox>
        <Heading elemType='h3' elemStyle='h4'>{t(PersonalInformationText.heading)}</Heading>
      </BorderLessGrayBox>
      {/* @ts-ignore */}
      <Scope scope='claimantData'>
        <Row>
          <Col md={6} lg={language === Language.ENGLISH ? 3 : 6}>
            <TextInput data-private={true} data-testid='firstName' label={t(PersonalInformationText.firstName)} ariaLabelText={t(PersonalInformationText.firstName)} field={'firstName'} validateOnBlur={true} validateOnChange={true} validate={validateName} 
    //@ts-ignore FIXME: type error
    initialValue={claimantData?.firstName || experience === Experience.EMPLOYEE && user?.firstName}/>
          </Col>
          <Col md={6} lg={language === Language.ENGLISH ? 3 : 6}>
            <TextInput data-testid='middleInitial' label={t(PersonalInformationText.middleInitial)} ariaLabelText={t(PersonalInformationText.middleInitial)} field={'middleName'} mask={middleInitialMask} initialValue={claimantData?.middleName}/>
          </Col>
          <Col md={6} lg={6}>
            <TextInput 
    //@ts-ignore FIXME: type error
    disabled={experience === Experience.EMPLOYEE && ((claimantData?.lastName && claimantData?.lastName !== "") || (user?.lastName && user?.lastName !== ""))} data-private={true} data-testid='lastName' label={t(PersonalInformationText.lastName)} ariaLabelText={t(PersonalInformationText.lastName)} field={'lastName'} validateOnBlur={true} validateOnChange={true} validate={validateName} 
    //@ts-ignore FIXME: type error
    initialValue={claimantData?.lastName || experience === Experience.EMPLOYEE && user?.lastName}/>
          </Col>
          <Col md={6} lg={6} xl={language === Language.ENGLISH ? 3 : 6}>
            <DatePickerV2 data-testid='dateOfBirth' theme={themes.lincoln} field={'dob'} label={experience === Experience.EMPLOYEE ? t(PersonalInformationText.dateOfBirthEE) : `${t(PersonalInformationText.dateOfBirthEE)} (optional)`} ariaLabelText={experience === Experience.EMPLOYEE ? t(PersonalInformationText.dateOfBirthEE) : `${t(PersonalInformationText.dateOfBirthEE)} (optional)`} validate={(props) => validateDateIsOnOrBeforeToday(props, t, experience === Experience.EMPLOYEE)} mask={dateMask} initialValue={claimantData?.dob} validateOnBlur={true} data-private={true}/>
          </Col>
          <Col md={6} lg={3}>
            <SelectInput data-testid='gender' field={'gender'} label={t(PersonalInformationText.gender)} ariaLabelText={t(PersonalInformationText.gender)} options={genderValues()} placeholderText={t(PersonalInformationText.gender)} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} errorMessage={selectInputErrorMessage} initialValue={claimantData?.gender}/>
          </Col>
        </Row>
      </Scope>
    </div>);
};
export default PersonalInformation;
