import React from 'react';
import styled from 'styled-components';
import Box from '@digitools/honest-react/dist/lib/components/Box';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import DentalText from '../constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import useMediaQuery from 'src/hooks/useMediaQuery';
import StatusBadge from 'src/components/Cards/StatusBadge';
const StyledItem = styled('div') `
  display: flex;
`;
const PatientStatusDiv = styled('div') `
  justify-content: space-between;
  align-items: stretch;
`;
const PatientDOB = styled('div') `
  border-left: ${({ theme }) => `3px solid ${theme.palette.primary}`};
  padding-left: 0.4rem;
`;
const StyledLabelHeader = styled.div `
  color: ${({ theme }) => theme.palette.primaryDarker};
  font-size: 0.9375rem;
  font-weight: 500;
`;
const getDentalStatusColor = (status) => {
    switch (status) {
        case 'INACTIVE':
        case 'Inactivo(a)':
            return 'gray';
        case 'FUTURE':
        case 'Futuro(a)':
            return 'grayDarker';
        default:
            return 'accent2Light';
    }
};
const MemberInformationBox = () => {
    const { currentMember } = useDentalDataContext();
    const isMobile = useMediaQuery('(max-width: 1439px)');
    const { t } = useTranslate();
    return (<div className={'h-100 border d-flex flex-column w-100 mt-0'}>
      {!!currentMember && (<>
          {!isMobile && (<Box theme={themes.lincoln} type={'header'} color={'primaryDark'}>
              <Heading elemType={'h2'} className={'d-flex'} theme={themes.lincoln} color={'white'} style={{ fontSize: '1.5rem', fontWeight: 300 }} data-testid={'patient-name'} data-private>
                {currentMember.lastName}, {currentMember.firstName}
              </Heading>
            </Box>)}
          <div className={isMobile ? 'p-3 d-flex h-100 ' : 'd-flex h-100 justify-content-between pl-3 pr-3 pt-0'}>
            <div className={'d-flex justify-content-between w-100'}>
              <div>
                <StyledItem data-testid={'patient-effective-date'} className='h-100 mb-1 mt-0'>
                  <div className={'d-inline-block'}>
                    <div id='effectiveDate'>{`${t(DentalText.effectiveDateText)}: ${currentMember.effectiveDate}`}</div>
                    {currentMember.terminationDate && (<div id='terminationDate'>{`${t(DentalText.terminationDateText)}: ${currentMember.terminationDate}`}</div>)}
                  </div>
                </StyledItem>
              </div>
              <div className={'h-100 alignRight'}>
                <StatusBadge data-testid={'status-badge'} backgroundColor={getDentalStatusColor(currentMember.status)}>
                  {currentMember.status}
                </StatusBadge>
              </div>
            </div>
          </div>
          <div className={'mb-0 p-3'}>
            <PatientStatusDiv hasTerminationDate={!!currentMember.terminationDate}>
              <PatientDOB theme={themes.lincoln} data-private>
                <StyledLabelHeader theme={themes.lincoln} data-testid={'patient-member-type'}>
                  {currentMember.memberTypeDescription}
                </StyledLabelHeader>
                <StyledItem data-testid={'patient-dob'}>
                  <div id='dateOfBirth'>{t(DentalText.dateOfBirthText)}:</div>
                  &nbsp;
                  <div aria-labelledby='patientDetails dateOfBirth' data-private={true}>
                    {currentMember.dateOfBirth}
                  </div>
                </StyledItem>
              </PatientDOB>
            </PatientStatusDiv>
          </div>
        </>)}
    </div>);
};
export default MemberInformationBox;
