import useDentalDataContext from "src/packages/dental/hooks/useDentalContext";
import { Button, Icon } from "@digitools/honest-react";
import React, { useState } from "react";
import { tealiumFormat } from "src/packages/dental/providers/DentalDataProvider";
import StatusBadge from "src/components/Cards/StatusBadge";
import DentalText from "src/packages/dental/constants/DentalText";
import { GetSummaryPdf } from "src/packages/dental/api/DentalApiCalls";
import useExperience from "src/hooks/useExperience";
import useAuthentication from "src/hooks/useAuthentication";
import useTranslate from "src/hooks/useTranslate";
import { TEALIUM_EVENT } from "src/constants/tealiumConstants";
import { DentalSubHeading } from "src/packages/dental/Dental";
import useMediaQuery from "src/hooks/useMediaQuery";
import { saveAs } from 'file-saver';
import Card from "src/components/Cards/Card";
const UsersIcon = () => (<Icon name='profile' size='s' style={{ marginTop: '.26rem' }} color={'grayDarker'}/>);
const FileIcon = () => (<Icon name='documents' size='s' style={{ marginTop: '.26rem' }} color={'grayDarker'}/>);
const getStatusColor = (status) => {
    switch (status) {
        case 'Closed':
        case 'Cerrado':
            return 'grayLight';
        case 'Open':
        case 'Abierto':
            return 'secondary';
        case 'Received':
        case 'Recibido':
            return 'success';
        default:
            return 'accent2Dark';
    }
};
const ClaimBoxes = () => {
    const { currentMember, setInfoLoading, tealiumFunction } = useDentalDataContext();
    const { t } = useTranslate();
    const { language } = useExperience();
    const [pdfError, setPdfError] = useState('');
    const { authHeader } = useAuthentication();
    const isMobile = useMediaQuery('(max-width: 738px)');
    const processSummaryPdf = () => {
        setInfoLoading(true);
        GetSummaryPdf(language, authHeader(), currentMember?.memberType, currentMember?.id)
            .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(blob, `Member_Dental_Claim_Information.pdf`);
        })
            .catch(error => {
            setPdfError(t(DentalText.pdfError));
        })
            .finally(() => {
            setInfoLoading(false);
        });
    };
    return (<>
            {isMobile ? (<div className={'d-flex justify-content-between h-100 w-100'}>
                <Button data-testid={'claimPdfButton'} onClick={() => {
                processSummaryPdf();
                tealiumFunction({ eventName: tealiumFormat(t(DentalText.downloadClaimText)), eventAction: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD });
            }} className={'pb-2 pr-0 pl-0 mr-0 mb-0 align-self-end'}>{<Icon name={'download'} color={'secondaryLight'}></Icon>}{' ' + t(DentalText.downloadClaimText)}</Button>
            </div>) : (<div className={'d-flex justify-content-between h-100 w-100'}><DentalSubHeading isMobile={isMobile} className={'pb-1 mb-0 align-self-end'} text={t(DentalText.claimsTitle)}/>
                <Button data-testid={'claimPdfButton'} onClick={() => {
                processSummaryPdf();
                tealiumFunction({ eventName: tealiumFormat(t(DentalText.downloadClaimText)), eventAction: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD });
            }} className={'pb-2 pr-0 mr-0 mb-0 align-self-end'}>{<Icon name={'download'} color={'secondaryLight'}></Icon>}{' ' + t(DentalText.downloadClaimText)}</Button>
            </div>)}

            {currentMember?.claims &&
            currentMember.claims?.map((claim) => (<>
                        {claim.status &&
                    <>
                                <Card data-testid={'outer-card-claims'} borderColor={getStatusColor(claim.status)} heading={<div>
                                            <DentalSubHeading isMobile={isMobile} className={'p-0 m-0'} secondaryDark={true} text={claim.displayTitle}>

                                            </DentalSubHeading>
                                        </div>} secondaryHeading={<StatusBadge backgroundColor={getStatusColor(claim.status)}>{claim.status}</StatusBadge>}>
                                    <div style={{ color: '#6b6b6b' }}>
                                        <div className={'d-flex w-100'}>
                                            <UsersIcon />
                                            <div style={{ paddingLeft: '.5rem' }}>{t(DentalText.claimsForText) + claim.patientName}</div>
                                                </div>

                                                <div className={'d-flex w-100'}>
                                                    <FileIcon />
                                                    <div style={{ paddingLeft: '.5rem' }}>{claim.claimNumber}</div>
                                                </div>
                                            </div>
                                </Card></>}</>))}</>);
};
export default ClaimBoxes;
