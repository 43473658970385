import auth0TokenManager from '@digitools/auth0-token-manager';
import JwtDecode from 'jwt-decode';
import LogRocket from 'logrocket';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'src/utils/withRouter';
import { getNewEoiInterviewFlag, getUserMetadata } from '../api/AuthServiceApi';
import { cancelToken } from '../api/TokenBlacklistServiceApi';
import { updateUserLastLogin } from '../api/UpdateProfileApi';
import PageLoader from '../components/PageLoader/PageLoader';
import { EMPLOYEE_HOME, EMPLOYER_HOME } from '../constants/applicationPermissionConstants';
import { TEALIUM_EVENT } from '../constants/tealiumConstants';
import withExperience from '../hocs/withExperience';
import withPortalStatus from '../hocs/withPortalStatus';
import withTealium from '../hocs/withTealium';
import { UnauthenticatedError } from '../types/UnauthenticatedError';
import { UserContextError } from '../types/UserContextError';
import { getEnvVariable } from '../utils/envUtils';
import { getJtiUUID } from '../utils/jtiUtils';
import Sawmill from '../utils/Sawmill';
import { AuthenticationContext } from './AuthenticationProvider';
import { getHashFragment } from 'src/api/GatewayApi';
export class Auth0Provider extends Component {
    state = {
        tokenManager: undefined,
        redirecting: false,
        jti: '',
    };
    async componentDidMount() {
        const { register } = this.props.authenticationContext;
        const { isPublic } = this.props;
        register({
            init: this.init,
            getUserContext: this.getUserContext,
            getIsAuthenticated: this.getIsAuthenticated,
            logout: this.logout,
            getAuthHeader: this.getAuthHeader,
            setCustomerInContext: this.setCustomerInContext,
            login: this.login,
            isPublic,
            hasValidSession: this.hasValidSession,
        });
    }
    componentWillUnmount() {
        this.props.authenticationContext.deregister();
    }
    init = async () => {
        const { audience, clientID, domain, redirectUri, navigate, location: { hash, pathname, search }, isPublic = true, userMetadataUrl, portalStatusContext: { maintenanceModeBackdoorAccessEnabled }, } = this.props;
        const currentDomain = this.getAuth0Domain(pathname, domain);
        let tokenManager = await auth0TokenManager.init({
            audience,
            clientID: this.getClientId(currentDomain),
            domain: currentDomain,
            redirectUri,
        });
        const jti = getJtiUUID();
        this.setState({ jti: jti });
        if (pathname.indexOf('samlp/') !== -1 || pathname.indexOf('samlpv2/') !== -1) {
            const connectionName = pathname.split('/').slice(-1).toString();
            if (pathname.indexOf('samlp/') !== -1) {
                tokenManager = await auth0TokenManager.init({
                    audience,
                    clientID: this.getClientId(getEnvVariable('auth0SSODomain')),
                    domain: getEnvVariable('auth0SSODomain'),
                    redirectUri,
                });
            }
            if (!!connectionName &&
                (!maintenanceModeBackdoorAccessEnabled ||
                    (maintenanceModeBackdoorAccessEnabled && connectionName === 'lfg-adfs'))) {
                this.setState({ redirecting: true });
                tokenManager.authorize({ connection: connectionName });
            }
            else {
                navigate('public/login');
            }
        }
        if (!isPublic) {
            // handle authentication if at callback uri
            const callbackHash = hash || search;
            const callbackState = new URLSearchParams(search).get('state');
            if (pathname === '/v2/callback' && (!!callbackState || !!callbackHash)) {
                const hashFragment = !!callbackState
                    ? await getHashFragment(callbackState).then(value => {
                        return value.data;
                    })
                    : callbackHash;
                LogRocket.info('past getHashFragment');
                if (hashFragment.indexOf('id_token') === -1) {
                    LogRocket.error('id_token not found in hash fragment', hashFragment);
                }
                try {
                    const decodedUrlHash = decodeURIComponent(hashFragment);
                    if (decodedUrlHash.indexOf(`Audience is invalid. Configured: urn:auth0:`) !== -1) {
                        const decodedTenants = decodedUrlHash.split('&')[1].split(':').slice(-2);
                        LogRocket.info('decodedTenants', decodedTenants);
                        const connectionDomain = this.getConnectionDomain(decodedTenants[0]);
                        const connectionName = decodedTenants[1];
                        tokenManager = await auth0TokenManager.init({
                            audience,
                            clientID: this.getClientId(connectionDomain),
                            domain: connectionDomain,
                            redirectUri,
                        });
                        this.handleIdpNoAudience(tokenManager, connectionName);
                    }
                    else {
                        if (decodedUrlHash.indexOf('Please verify your email before logging in.') !== -1) {
                            LogRocket.error('User must verify email');
                            throw { errorDescription: 'User must verify email' };
                        }
                        //Get issuer from ID token(Always JWT format as per OIDC spec). Access token can be JWT or opaque token
                        const decodedIdTokenJwt = JwtDecode(hashFragment.slice(hashFragment.indexOf('id_token')).split('=')[1]);
                        LogRocket.info('decodedIdTokenJwt.iss', decodedIdTokenJwt.iss);
                        const decodedDomain = decodedIdTokenJwt.iss
                            .replace(/^https:\/\//, '')
                            .replace(/^http:\/\//, '')
                            .replace(/\/+/g, '/')
                            .replace(/\/+$/, '');
                        if (decodedDomain !== domain) {
                            if (decodedDomain !== getEnvVariable('auth0Domain') &&
                                decodedDomain !== getEnvVariable('auth0SSODomain')) {
                                LogRocket.error('invalid domain: ' + decodedDomain);
                                throw new UnauthenticatedError('invalid domain: ' + decodedDomain);
                            }
                            localStorage.setItem('mlpDomain', decodedDomain);
                            LogRocket.info('Reinitializing tokenManager for decodedDomain', decodedDomain);
                            tokenManager = await auth0TokenManager.init({
                                audience,
                                clientID: this.getClientId(decodedDomain),
                                domain: decodedDomain,
                                redirectUri,
                            });
                            LogRocket.info('Reinitialized tokenManager');
                            this.setState({ tokenManager });
                        }
                        // parse the url hash
                        const decodedHash = await tokenManager.handleAuthentication({ hash: hashFragment });
                        const redirectUrl = sessionStorage.getItem('redirectUri');
                        LogRocket.info('redirectUrl', redirectUrl);
                        const profile = decodedHash.idTokenPayload[userMetadataUrl];
                        await this.addAppPermissionsToProfile(profile, tokenManager);
                        if (profile.loginId) {
                            //add loginId to session storage so we can access it for SSO purposes
                            sessionStorage.setItem('loginId', profile.loginId);
                        }
                        if (profile.isLfgUser) {
                            //add isLfgUser to session storage to help navigate to LFG login page
                            //Interim, goes away after SPOE goes live
                            sessionStorage.setItem('isLfgUser', profile.isLfgUser);
                        }
                        // wrap in if statement for now so we dont get console errors
                        // due to missing appId for prod because we are in the POC phase
                        const logRocketAppId = getEnvVariable('LOGROCKET_APP_ID');
                        if (logRocketAppId) {
                            LogRocket.identify(profile.loginId, {
                                isLFGUser: profile.isLfgUser,
                                isSSO: profile.ssoUser,
                            });
                        }
                        try {
                            LogRocket.info('before updateUserLastLogin');
                            await updateUserLastLogin(tokenManager.getAuthHeader(), profile.loginId, {
                                lastLogin: decodedHash.idTokenPayload.updated_at,
                            });
                        }
                        catch (e) {
                            LogRocket.error('Failed to update User Last Login', e);
                            Sawmill.error(e, `Failed to update User Last Login for ${profile.loginId}`);
                            // moving on; on purpose?
                        }
                        LogRocket.info('idTokenPayload.sub', decodedHash.idTokenPayload.sub);
                        if ((decodedHash.idTokenPayload.sub.indexOf('samlp') !== -1 ||
                            decodedHash.idTokenPayload.sub.indexOf('samlpv2') !== -1) &&
                            maintenanceModeBackdoorAccessEnabled) {
                            navigate('/public/login');
                        }
                        if ((profile.ssoUser &&
                            (profile.termsAndConditionsAccepted === undefined ||
                                profile.firstName.toLowerCase() === 'EMPTY_FIRST_NAME'.toLowerCase() ||
                                profile.lastName.toLowerCase() === 'EMPTY_LAST_NAME'.toLowerCase())) ||
                            !profile.termsAndConditionsAccepted ||
                            (profile.elecConsentAcceptedDate === null && !profile.isLfgUser && !profile.userInternal)) {
                            const callbackForTermsAndConditions = decodedHash.idTokenPayload.sub.split('|')[1];
                            // SSO user needs to accept terms and conditions so sending them to modal for confirmation
                            navigate('/employee/home', {
                                state: { action: 'needsToAcceptTermsAndConditions', callbackRoute: callbackForTermsAndConditions },
                            });
                        }
                        else {
                            const loginsCount = decodedHash.idTokenPayload[getEnvVariable('vanityUrl') + 'logins_count'];
                            if (!!loginsCount && loginsCount === 1) {
                                this.props.trackEvent({
                                    event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                                    event_type: TEALIUM_EVENT.EVENT_TYPE.LOGIN,
                                    event_name: 'User First Login',
                                });
                            }
                            // if a redirect url exists, AND they have a customerInContext send the user to their destination
                            if (!!redirectUrl && profile.customerInContext) {
                                navigate(redirectUrl);
                            }
                            else {
                                if (profile.accessAllCustomers || !profile.customers || profile.customers.length !== 1) {
                                    navigate('/customer-search');
                                }
                                else if (profile.customerInContext) {
                                    tokenManager.setLoginHint(profile.customerInContext.customerId);
                                    const experience = this.getExperience(profile.customerInContext.applicationPermissions);
                                    const url = profile.ssoUser
                                        ? await this.getSSODeepLinkURL(profile.ssoCargo.landingPage, experience, tokenManager)
                                        : '';
                                    if (url !== '') {
                                        window.location.assign(getEnvVariable('mlpHomeUrl') + url);
                                    }
                                    else {
                                        if (experience === 'employer') {
                                            navigate('/employer/home');
                                        }
                                        else if (experience === 'employee') {
                                            navigate('/employee/home');
                                        }
                                        else {
                                            LogRocket.error('no url; neither employer experience nor employee experience', url, experience);
                                        }
                                    }
                                }
                                else {
                                    LogRocket.error('not all customers and no customerInContext');
                                }
                            }
                        }
                    }
                }
                catch (e) {
                    Sawmill.error(e, 'Failed authentication');
                    console.log(JSON.stringify(e));
                    LogRocket.error('Failed authentication', e);
                    if (e.errorDescription === 'User must verify email') {
                        navigate({
                            pathname: '/public/verify-email',
                        });
                    }
                    else {
                        navigate('/public/login', {
                            state: { error: { message: e.errorDescription, type: e.error } },
                        });
                    }
                }
            }
            else {
                LogRocket.info('tokenManager.isAuthenticated', tokenManager.isAuthenticated());
                if (!tokenManager.isAuthenticated()) {
                    sessionStorage.setItem('redirectUri', pathname);
                    navigate({ pathname: '/public/login' });
                }
            }
        }
        return new Promise(resolve => {
            this.setState({ tokenManager }, () => resolve());
        });
    };
    handleIdpNoAudience = (tokenManager, connectionName) => {
        this.checkAndUpdateJTI();
        const { navigate, portalStatusContext: { maintenanceModeBackdoorAccessEnabled }, } = this.props;
        if (maintenanceModeBackdoorAccessEnabled) {
            navigate('/public/login');
        }
        else {
            // Re-Authenticate use with valid Audience for IDP initiated SSO Users
            tokenManager.authorize({ connection: connectionName });
        }
    };
    getClientId = (domainName) => {
        if (domainName === getEnvVariable('auth0Domain')) {
            return getEnvVariable('auth0ClientId');
        }
        return getEnvVariable('auth0SSOClientId');
    };
    getAuth0Domain = (pathname, domain) => {
        this.checkAndUpdateJTI();
        if (pathname.indexOf('public/login') !== -1) {
            localStorage.removeItem('mlpDomain');
            return domain;
        }
        return !!localStorage.getItem('mlpDomain') ? localStorage.getItem('mlpDomain') : domain;
    };
    setCustomerInContext = async (customerId) => {
        this.checkAndUpdateJTI();
        this.state.tokenManager.setLoginHint(customerId);
        const user = await this.getUserContext(true);
        return user;
    };
    getUserContext = async (updateToken) => {
        this.checkAndUpdateJTI();
        try {
            const { userMetadataUrl } = this.props;
            if (updateToken) {
                await this.state.tokenManager.renewToken();
            }
            const idToken = await this.state.tokenManager.getProfile();
            const profile = idToken[userMetadataUrl];
            await this.addAppPermissionsToProfile(profile, this.state.tokenManager);
            const userMetadata = {
                ...profile,
                ...profile.customerInContext,
                userName: profile.loginId,
                roles: profile.roleNames,
                isMigratedToAuth0: idToken.sub.startsWith('auth0'),
                isEmailVerified: idToken.email_verified,
            };
            return userMetadata;
        }
        catch (e) {
            console.log('error fetching user context: ', e);
            throw new UserContextError(e);
        }
    };
    getAuthHeader = async () => {
        this.checkAndUpdateJTI();
        const authHeader = await this.state.tokenManager.getAuthHeader();
        return authHeader;
    };
    getIsAuthenticated = async () => {
        this.checkAndUpdateJTI();
        return Promise.resolve(this.state.tokenManager.isAuthenticated());
    };
    hasValidSession = async () => {
        return this.state.tokenManager.renewToken();
    };
    logout = async (logoutUrlOverride) => {
        const { experienceContext: { language }, logoutUrl, authenticationContext, } = this.props;
        sessionStorage.removeItem('redirectUri');
        localStorage.removeItem('mlpDomain');
        sessionStorage.removeItem('link');
        sessionStorage.removeItem('isLfgUser');
        try {
            await cancelToken(getJtiUUID());
        }
        catch (e) {
            console.error(e);
            // Don't stop logout from continuing if token can't be blacklisted
        }
        // if SSO send to SSO specific logout page
        if (authenticationContext.user.isLfgUser) {
            await this.state.tokenManager.logout(getEnvVariable('lfgHomeUrl') + `/secure/logout`);
        }
        else if (authenticationContext.user.ssoUser) {
            await this.state.tokenManager.logout(window.location.origin + `/customer/public/ssoLogout`);
        }
        else {
            await this.state.tokenManager.logout(`${logoutUrlOverride || logoutUrl}?lang=${language}`);
        }
    };
    login = (username, password, errorCallback) => {
        this.state.tokenManager.login(username, password, errorCallback);
        /* clear out download flags for VOC survey */
        sessionStorage.setItem('download_flag', '');
        sessionStorage.setItem('downloads', '');
        /* end clear out download flags for VOC survey */
    };
    render() {
        const { authenticationContext: { loading }, children, } = this.props;
        const { redirecting } = this.state;
        return loading || redirecting ? <PageLoader id='auth0-provider-spinner'/> : <>{children}</>;
    }
    getConnectionDomain(tenantName) {
        const audienceDomainMap = {
            'lfg-digital-mlp-nonprod': 'auth.np-mylincolnportal.com',
            'lfg-digital-mlp-prod': 'auth.mylincolnportal.com',
            'lfg-digital-nonprod': 'lfg-digital-nonprod.auth0.com',
            'lfg-digital-prod': 'lfg-digital-prod.auth0.com',
        };
        return audienceDomainMap[tenantName];
    }
    getExperience(applicationPermissions) {
        this.checkAndUpdateJTI();
        if (applicationPermissions.indexOf(EMPLOYER_HOME) > -1) {
            return 'employer';
        }
        else if (applicationPermissions.indexOf(EMPLOYEE_HOME) > -1) {
            return 'employee';
        }
        else {
            return '';
        }
    }
    async getSSODeepLinkURL(landingPage, experience, tokenManager) {
        this.checkAndUpdateJTI();
        switch (landingPage) {
            case 'EOI_OVERVIEW': {
                try {
                    const response = await getNewEoiInterviewFlag(tokenManager.getAuthHeader());
                    if (!response.data) {
                        return '/eoi';
                    }
                }
                catch (e) {
                    console.log('error retrieving version of eoi: ', e);
                }
                return '/customer/eoi';
            }
            case 'STATUS_RESULTS':
                return '/status/' + experience;
            case 'CLAIM_LEAVE_OVERVIEW': {
                // TODO: remove conditionals for different CLI endpoints when new CLI is live
                try {
                    const hostname = window.location.hostname;
                    // return new CLI url is NP, else old CLI url
                    if (hostname.indexOf('np-mylincolnportal.com') > -1) {
                        return '/customer/cli/' + experience + '/intake';
                    }
                    else {
                        return '/cli/' + experience;
                    }
                }
                catch (e) {
                    console.log('error retrieving version of CLI: ', e);
                }
                return '/cli/' + experience;
            }
            case 'CLAIM_LEAVE_ABOUT_YOU': {
                // TODO: remove conditionals for different CLI endpoints when new CLI is live
                try {
                    const hostname = window.location.hostname;
                    // return new CLI url is NP, else old CLI url
                    if (hostname.indexOf('np-mylincolnportal.com') > -1) {
                        return '/customer/cli/' + experience + '/intake';
                    }
                    else {
                        return '/cli/' + experience + '/claimant';
                    }
                }
                catch (e) {
                    console.log('error retrieving version of CLI: ', e);
                }
                return '/cli/' + experience + '/claimant';
            }
            default:
                return '';
        }
    }
    async addAppPermissionsToProfile(profile, tokenManager) {
        LogRocket.info('addAppPermissionsToProfile');
        this.checkAndUpdateJTI();
        if (profile.customerInContext && profile.customerInContext.customerId) {
            try {
                const userMetadata = await getUserMetadata(tokenManager.getAuthHeader(), profile.loginId, profile.customerInContext.customerId);
                profile.customerInContext.applicationPermissions = userMetadata.data.customerInContext.applicationPermissions;
                profile.customerInContext.smallMid = userMetadata.data.customerInContext.smallMid;
            }
            catch (e) {
                Sawmill.error(e, `Failed to get User Application Permissions for ${profile.loginId}`);
                LogRocket.error('Failed to get User Application Permissions', e);
                throw new UserContextError(e);
            }
        }
    }
    async checkAndUpdateJTI() {
        if (this.state.jti !== getJtiUUID()) {
            try {
                await cancelToken(this.state.jti);
            }
            catch (e) {
                console.error(e);
            }
            this.setState({ jti: getJtiUUID() });
        }
    }
}
export default withExperience(withPortalStatus(withCookies(withTealium(withRouter((props) => (<AuthenticationContext.Consumer>
            {(context) => <Auth0Provider {...props} authenticationContext={context}/>}
          </AuthenticationContext.Consumer>))))));
