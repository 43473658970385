export function trimLeadingZeros(officeNum) {
    if (officeNum) {
        return officeNum.toUpperCase().trim().replace(/^0+/, '');
    }
    return officeNum;
}
export function getOffice(cliOfficeInfo, prodType) {
    const officePhoneNumber = (prodType === "LTD" ? getOfficeCustomizedInfo(cliOfficeInfo, 'customLTDVanityLine', 'selectedLTDPhone') : prodType === 'LEAVE' ? getOfficeCustomizedInfo(cliOfficeInfo, 'customLeaveVanityLine', 'selectedLeavePhone') : getOfficeCustomizedInfo(cliOfficeInfo, 'customSTDVanityLine', 'selectedSTDPhone'));
    const officeFaxNumber = (prodType === "LTD" ? getOfficeCustomizedInfo(cliOfficeInfo, 'customLTDVanityFax', 'selectedLTDFax') : prodType === 'LEAVE' ? getOfficeCustomizedInfo(cliOfficeInfo, 'customLeaveVanityFax', 'selectedLeaveFax') : getOfficeCustomizedInfo(cliOfficeInfo, 'customSTDVanityFax', 'selectedSTDFax'));
    const emailAddress = getOfficeCustomizedInfo(cliOfficeInfo, 'customLeaveVanityEmail', 'selectedLeaveEmail');
    return {
        officePhoneNumber,
        officeFaxNumber,
        emailAddress
    };
}
function getOfficeCustomizedInfo(contactInfo, vanityContactField, selectedContactField) {
    if (contactInfo != null && contactInfo.statusContactDetails != null) {
        if (contactInfo.statusContactDetails[vanityContactField] !== "") {
            return contactInfo.statusContactDetails[vanityContactField];
        }
        return contactInfo.statusContactDetails[selectedContactField];
    }
    return "";
}
