import { useEffect } from 'react';
import { decode } from 'status/utilities/hash';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import { useParams } from 'react-router-dom';
import { GetBenefitsAndFrequencies, GetClaims, GetDeductibles, GetWaitingPeriods } from 'src/packages/dental/api/DentalApiCalls';
import useAuthentication from 'src/hooks/useAuthentication';
import useExperience from 'src/hooks/useExperience';
import { tealiumFunctionBuilder, } from 'src/packages/dental/providers/DentalDataProvider';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from "src/constants/tealiumConstants";
const UseSetCurrentMember = () => {
    const { subscriber, setCurrentMember, setInfoLoading, setError, error, setTealiumFunction, dependentArray, setDependentArray, } = useDentalDataContext();
    const { memberId } = useParams();
    const { authHeader } = useAuthentication();
    const { language } = useExperience();
    const getMemberEvent = (currentMember, subscriber) => {
        if (currentMember && currentMember?.id !== subscriber?.id) {
            return 'member' + subscriber?.dependents?.indexOf(currentMember);
        }
        else {
            return 'subscriber';
        }
    };
    const { trackEvent, trackView } = useTealium();
    useEffect(() => {
        const decodedMemberId = decode(memberId);
        if (subscriber && (!memberId || subscriber.id === decodedMemberId)) {
            setCurrentMember(subscriber);
            setTealiumFunction(tealiumFunctionBuilder({ memberEvent: 'subscriber', trackEvent: trackEvent }));
        }
        else {
            const currentDependent = dependentArray && dependentArray.find(dep => dep.language == language && dep.member.id === decodedMemberId);
            if (currentDependent) {
                setCurrentMember(currentDependent.member);
                setTealiumFunction(tealiumFunctionBuilder({
                    memberEvent: getMemberEvent(currentDependent.member, subscriber),
                    trackEvent: trackEvent,
                }));
                trackView({
                    page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.DENTAL,
                    page_l4: 'employee view',
                });
            }
            else if (memberId && subscriber?.dependents) {
                const dependent = subscriber.dependents.find(dep => dep.id === decodedMemberId);
                if (dependent) {
                    //check we got a match
                    setCurrentMember(dependent);
                    setInfoLoading(true);
                    (async () => {
                        error.waitingPeriods = undefined;
                        error.benefits = undefined;
                        const benefitsResponse = await GetBenefitsAndFrequencies(authHeader(), language, dependent.id);
                        const deductiblesResponse = await GetDeductibles(authHeader(), language, dependent.memberType, dependent.id);
                        const waitingPeriodsResponse = await GetWaitingPeriods(authHeader(), language, dependent.memberType, dependent.id);
                        const claimsResponse = await GetClaims(authHeader(), language, dependent.memberType, dependent.id);
                        dependent.benefitsAndFrequencies = benefitsResponse?.benefitsAndFrequencies;
                        dependent.deductiblesAndCoinsurance = deductiblesResponse?.deductiblesAndCoinsurance;
                        dependent.waitingPeriods = waitingPeriodsResponse?.waitingPeriods;
                        dependent.claims = claimsResponse?.claims;
                        setError({
                            subscriber: error.subscriber,
                            benefits: benefitsResponse.graphQlError,
                            deductiblesAndCoinsurance: deductiblesResponse.graphQlError,
                            waitingPeriods: waitingPeriodsResponse.graphQlError,
                            claims: claimsResponse.graphQlError,
                        });
                        if (claimsResponse.graphQlError === 'HIPPA_VIOLATION') {
                            dependent.hippaViolation = true;
                        }
                        setDependentArray([...dependentArray, { language: language, member: dependent }]);
                        setTealiumFunction(tealiumFunctionBuilder({
                            memberEvent: getMemberEvent(dependent, subscriber),
                            trackEvent: trackEvent,
                        }));
                        trackView({
                            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.DENTAL,
                            page_l4: 'employee view',
                        });
                    })().finally(() => setInfoLoading(false));
                }
            }
        }
    }, [memberId]);
};
export default UseSetCurrentMember;
